import "./styles.css";
import YoutubeEmbed from "../../components/YouTube";
import PageHeader from "../../components/PageHeader";
import Arrows from "../../components/Arrows";
import IntroMenu from "../../components/IntroMenu";
import { Link } from 'react-router-dom';

function ModuleSevenA() {
    return (
        <div className="module-container">
            {/* Inside the PageHeader, enter a module title, time to complete, true or false if it is a exercise and a maximum or three descriptive points */}
            <PageHeader
                title="Life Control"
                time="10 Minutes"
                exercise={false}
                points={["Introduction to Life Control", "How to use the exercises.", "A number of exercises to improve your Life Control."]}
            />

            <div className="body-container">
                <div className="video-container">
                    {/* Insert links to videos below */}
                    <YoutubeEmbed embedId="-0X1_umZxzI" />

                    <div className="exercises-container">
                        <h2>Exercises</h2>
                        <ul>
                            <li><Link to="/think-three-positives">Think Three Positives</Link></li>
                            <li><Link to="/scent-of-lemon">Scent of a Lemon</Link></li>
                            <li><Link to="/managing-time">Managing My Time</Link></li>
                            <li><Link to="/goal-setting">Goal Setting</Link></li>
                            <li><Link to="/ants-into-pets">Turning ANTS into PETS</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="menu-arrow-container">
                    {/* Links to next and previous modules, if none put {null} */}
                    <Arrows next="/module-7b" previous="/module-6" />

                    {/* Update menu in IntroMenu.jsx */}
                    <IntroMenu />
                </div>
            </div>

        </div>
    )
}

export default ModuleSevenA
